:root {
  --bs-body-bg: #ffffff;
  --bs-body-color: #525f7f;
  --bs-light-rgb: 241, 245, 249;
  --bs-light: #f1f5f9;
  --bs-border-color-translucent: rgba(9, 9, 11, 0.05);
  --bs-primary: #5c5cff;
  --bs-primary-linear: #8960ff;
  --bs-primary-gradient: linear-gradient(
    90deg,
    var(--bs-primary),
    var(--bs-primary-linear)
  );
  --bs-secondary: #3f4247;
  --bs-highlight-color: #d3f36b;
  --bs-secondary-color: rgb(172 174 175);
  --bs-nav-pills-link-active-bg: var(--bs-primary);
  --bs-sidebar-width: 15rem;
  --bs-heading-color: #334155;
  --bs-border-radius: 0.75rem;
  --bs-border-radius-sm: 0.35rem;
  --bs-border-color: var(--bs-border-color-translucent);
  --bs-box-shadow: 0 1px 2px rgba(50, 50, 71, 0.08);
}
