.modal {
  --bs-modal-border-color: none;
  --bs-modal-padding: 1.5rem;
  --bs-modal-header-padding: 1.5rem 1.5rem;
  --bs-modal-padding: 1.5rem;
  --bs-modal-width: 550px;
}

.modal-backdrop {
  --bs-backdrop-zindex: 100;
  --bs-backdrop-bg: rgba(19, 33, 68, 0.25);
  --bs-backdrop-opacity: 1;
}
