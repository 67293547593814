.search-box {
  cursor: pointer;
  width: 120px;
  transition: width 0.1s ease;

  &__icon {
    padding: 9px 11px 4px;
  }

  &__shortcut {
    top: 0;
    padding: 8px 12px 8px;
    kbd {
      background-color: var(--bs-light);
      font-size: var(--bs-body-font-size);
      padding: 2px 12px;
    }
  }

  .form-control {
    padding-left: 40px;
  }

  &:hover,
  &:focus,
  &.on-focus {
    .search-box__icon {
      i {
        color: var(--bs-primary);
      }
    }
    .search-box__shortcut {
      kbd {
        color: var(--bs-secondary-color);
      }
    }
  }

  &.on-focus {
    width: 350px;
  }
}
