.btn {
  outline-style: solid;
  outline-width: 0px;
  outline-offset: 2px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &.btn-primary {
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-disabled-color: var(--bs-white);
    --bs-btn-hover-bg: var(--bs-primary);
    background-image: var(--bs-primary-gradient);
    outline-color: var(--bs-primary);
  }

  &.btn-danger {
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-disabled-color: var(--bs-white);
    outline-color: var(--bs-danger);
  }

  &.btn-secondary {
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-active-color: var(--bs-white);
    --bs-btn-disabled-color: var(--bs-white);
    --bs-btn-hover-bg: var(--bs-secondary);
    outline-color: var(--bs-secondary);
  }

  &.btn-light {
    --bs-btn-color: var(--bs-body-color);
    --bs-btn-hover-bg: var(--bs-light);
    --bs-btn-border-color: var(--bs-border-color-translucent);
    --bs-btn-hover-bg: var(--bs-border-color-translucent);
    outline-color: var(--bs-border-color-translucent);
  }

  &.btn-transparent {
    --bs-btn-hover-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-bg: var(--bs-light);
    --bs-btn-active-bg: var(--bs-light);
    --bs-btn-active-border-color: var(--bs-light);
    outline-color: var(--bs-light);
  }

  &.btn-white {
    --bs-btn-bg: var(--bs-white);
    --bs-btn-hover-bg: var(--bs-white);
    --bs-btn-border-color: var(--bs-border-color-translucent);
    --bs-btn-hover-bg: var(--bs-white);
    --bs-btn-active-bg: var(--bs-white);
    --bs-btn-active-border-color: var(--bs-border-color-translucent);
    --bs-btn-hover-border-color: var(--bs-border-color-translucent);
    outline-color: var(--bs-border-color-translucent);
  }

  &.btn-none {
    --bs-btn-hover-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-bg: transparent;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;
    outline-color: transparent;
  }

  &:hover {
    text-decoration-line: none;
    outline-width: 1px;
  }

  &.btn-icon {
    padding: 6px;
    &:hover,
    &:active,
    &:focus {
      outline-width: 0 !important;
      border-color: transparent !important;
    }
    &:disabled {
      border-color: transparent;
    }
  }
}
