.nav-pills {
  .nav-link {
    --bs-nav-link-color: var(--bs-body-color);
    &:hover {
      background-color: var(--bs-light);
    }
    &.active {
      background-color: var(--bs-primary);
    }
  }
}

.nav-segment {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: var(--bs-body-color);
  background-color: var(--bs-light);
  border-radius: var(--bs-border-radius);
  padding: 4px;

  .nav-link {
    --bs-nav-link-color: var(--bs-body-color);
    &.active {
      background-color: var(--bs-white);
      border-radius: var(--bs-border-radius);
      color: var(--bs-heading-color);
      box-shadow: 0 .1875rem .375rem 0 rgba(140, 152, 164, .25);
    }
  }
}

.nav-underline {
  --bs-nav-link-padding-y: 0.4rem;
  --bs-nav-link-color: var(--bs-body-color);
  --bs-nav-underline-link-active-color: var(--bs-primary);
  .icon {
    color: var(--bs-body-color);
    text-decoration: none;
    &:hover {
      background-color: var(--bs-light);
    }
    &:active {
      color: var(--bs-white);
      background-color: var(--bs-primary);
    }
  }
}
