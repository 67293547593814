.form-label {
  margin-bottom: 3px !important;
  font-weight: 600;
  margin-left: 3px;
}

.form-text {
  margin-left: 3px;
}

.form-control {
  &:focus {
    box-shadow: none !important;
  }
  &:invalid,
  &.is-invalid {
    background-image: none !important;
  }
}

.monaco-editor {
  border-radius: var(--bs-border-radius) !important;
  .overflow-guard {
    border-radius: var(--bs-border-radius) !important;
  }
}

.password-group {
  .password-toggle {
    top: 25px;
  }
}

.dropdown-select {
  .dropdown-menu {
    box-shadow: var(--bs-box-shadow);
  }
}
