.sub-container {
  max-width: 56rem;
  margin: 0 auto;
}

.min-h-100 {
  min-height: 100vh;
}
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.main {
  @media (min-width: 992px) {
    margin-left: var(--bs-sidebar-width);
  }
}

.divider-center {
  &::before {
    margin-right: 1.5rem;
  }
  &::after {
    margin-left: 1.5rem;
  }
  &::after,
  &::before {
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
    margin-top: 0.0625rem;
    content: "";
  }
}

.hover-flex {
  &:hover {
    .child {
      display: flex;
    }
  }
  .child {
    display: none;
  }
}

code {
  font-size: var(--bs-body-font-size);
}

.text-gradient {
  --primary-dark-12: hsl(239 84% calc(67% - 12%));
  --primary-dark-22: hsl(239 84% calc(67% - 22%));
  --primary-dark-6: hsl(239 84% calc(67% - 6%));
  background: linear-gradient(
    45deg,
    var(--bs-primary),
    var(--primary-dark-12),
    var(--primary-dark-22),
    var(--primary-dark-6),
    var(--bs-primary)
  );
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: shine 8s linear infinite;
}

.link-hover-primary {
  transition: color 0.3s;
  &:hover {
    color: var(--bs-primary);
  }
}

.first-letter-cap::first-letter {
  text-transform: uppercase;
}
