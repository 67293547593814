.multi-field {
  .resumed {
    max-height: 265px;
    overflow-y: hidden;
  }
  .shadow-bottom {
    box-shadow: 0px -20px 15px var(--bs-white);
    z-index: 5;
    position: relative;
  }
}
