.dropdown-toggle {
  &::after {
    display: none;
  }
}

.dropdown-menu {
  --bs-dropdown-box-shadow: 0 4px 6px -2px #00000908, 0 12px 16px -4px #00000914;
  box-shadow: var(--bs-dropdown-box-shadow);
}

.dropdown-item {
  &:hover {
    &.option-edit {
      i {
        color: var(--bs-primary);
      }
    }
    &.option-delete {
      i {
        color: var(--bs-danger);
      }
    }
  }
  &:active,
  &.active {
    i {
      color: var(--bs-white) !important;
    }
    small {
      color: var(--bs-white);
    }
  }
}
