.animate-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  height: 100%;
  width: 100%;
  border-radius: 45%;
  animation: tooliconanim 5s infinite both alternate ease-in-out;
}

@keyframes tooliconanim {
  0% {
    opacity: 0.8;
    transform: rotate(0) scale(1);
  }

  10% {
    opacity: 0.7;
    transform: rotate(15deg) scale(1);
  }

  20% {
    opacity: 0.65;
    transform: rotate(35deg) scale(1.05);
  }

  30% {
    opacity: 0.55;
    transform: rotate(65deg) scale(1.1);
  }

  40% {
    opacity: 0.65;
    transform: rotate(135deg) scale(1.05);
  }

  50% {
    opacity: 0.8;
    transform: rotate(180deg) scale(1);
  }

  60% {
    opacity: 0.7;
    transform: rotate(200deg) scale(0.95);
  }

  70% {
    opacity: 0.65;
    transform: rotate(235deg) scale(0.9);
  }

  80% {
    opacity: 0.55;
    transform: rotate(275deg) scale(0.95);
  }

  90% {
    opacity: 0.75;
    transform: rotate(325deg) scale(1.05);
  }

  to {
    opacity: 0.8;
    transform: rotate(360deg) scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.refresh-icon {
  animation: spin 1s linear infinite;
  font-size: 2rem;
  color: #0d6efd;
}
