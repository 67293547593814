.table {
  --bs-table-striped-bg: var(--bs-light);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-color: var(--bs-body-color);
  --bs-table-border-color: var(--bs-light);
  tbody {
    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
