.accordion {
  &.accordion-flush {
    --bs-accordion-border-color: transparent;
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-active-color: var(--bs-heading-color);
    --bs-accordion-active-bg: transparent;
    .accordion-button {
      color: var(--bs-heading-color);
      box-shadow: none;
      padding: 0;
      margin: 0;
      @extend .h6;
      &:focus {
        z-index: 3;
        outline: 0;
        box-shadow: none;
      }
    }
    .accordion-collapse {
        margin-top: 7px;
    }
  }
}
