.body-wrapper-overlay {
  .body-overlay {
    height: calc(100% - 24px - 1rem);
    min-height: 300px;
    z-index: 3;
    margin-top: 24px;
  }
}

.ai__buttons {
  .sub-positioned {
    position: absolute;
    top: -18px;
  }
}
