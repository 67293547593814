.request__item {
  transition: border-color 0.3s;
  &.active {
    border-right: 5px solid var(--bs-primary);
  }
  &:hover:not(.active) {
    border-color: var(--bs-secondary-bg-subtle);
    transition: border-color 0.3s;
  }
}
