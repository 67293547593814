$font-family-sans-serif: "Inter", sans-serif;

$primary: #5c5cff;
$danger: #ed4c78;
$secondary: #3f4247;
$highlight: #d3f36b;

$box-shadow: 0 1px 2px rgba(50, 50, 71, 0.08);

$font-size-base: 0.875rem;
$headings-font-weight: 500;

$enable-negative-margins: true;

$input-padding-y: 0.45rem;
$input-btn-padding-y: 0.45rem;

$display-font-sizes: (
  6: 1rem,
);
