.card-wrapper {
  --bs-border-color-from: rgb(132 108 249 / 45%);
  --bs-border-color-to: rgb(48 200 98 / 35%);

  position: relative; /* Added for pseudo-element positioning */
  border-radius: var(--bs-border-radius);
  padding: 1px;
  border: 1px solid var(--bs-border-color-translucent);
  transition: border-color 0.3s;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background-image: linear-gradient(
      to top left,
      var(--bs-border-color-from),
      var(--bs-border-color-to)
    );
    opacity: 0;
    transition: opacity 0.3s;
    z-index: -1;
  }

  &:hover {
    border-color: transparent;

    &::before {
      opacity: 1;
    }
  }

  .card {
    border: 0;
    position: relative;
  }
}

.card {
  &__upload {
    border: 2px dashed var(--bs-light);
    transition: border 0.3s;
    &:hover {
      border: 2px dashed var(--bs-primary);
    }
  }
  &__action {
    transition: border-color 0.3s;
    &:hover {
      border-color: var(--bs-secondary-bg-subtle);
    }
  }
}
