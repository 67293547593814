html,
body {
  font-optical-sizing: auto;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 992px) {
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
}
