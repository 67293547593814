.code-window {
  position: relative;
  min-height: 320px;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-border-color);
  border-radius: 0.5rem;
  box-shadow: var(--bs-box-shadow);
  overflow: hidden;

  .window-head {
    display: flex;
    align-items: center;
    height: 2.75rem;
    background-color: var(--bs-white);
    border-bottom: 1px solid var(--bs-border-color);
    padding: 0 0.5rem;

    .dots {
      .dot {
        border-radius: 50%;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 0.5rem;
      }
    }

    .tab {
      position: relative;
      bottom: -5px;
      height: 2.25rem;
      background: var(--bs-light);
      border: 1px solid var(--bs-border-color);
      border-bottom: none;
      border-radius: 0.5rem 0.5rem 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1.15rem;
      margin-left: 1rem;
      font-family: monospace;
      font-size: 0.9rem;
      color: var(--bs-body-color);
    }
  }

  .window-body {
    min-height: calc(320px - 2.75rem);
    display: flex;
    align-items: stretch;
    background-color: var(--bs-light);

    .code-line {
      font-size: 0.9rem;
      font-family: monospace;
      color: var(--bs-body-color);
      background-color: var(--bs-white);
      padding: 5px 14px 0;
      min-width: 50px;
    }

    .code-content {
      height: 100%;
      width: 100%;
      padding: 1rem;

      .code-content-line {
        min-height: 1.75rem;
        font-size: 0.9rem;
        font-family: monospace;
        text-align: left;
        color: var(--bs-body-color);
        line-break: anywhere;
        padding-bottom: 3px;

        &.indented-1 {
          margin-left: 1.5rem;
        }
        &.indented-2 {
          margin-left: 3rem;
        }
        &.indented-3 {
          margin-left: 4.5rem;
        }
      }
    }
  }
}
