.copy-to-clipboard {
  position: relative;

  .copy-icon {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .copy-icon {
      opacity: 1;
    }
  }
  .btn {
    &.btn-icon {
      position: absolute;
      right: -30px;
      padding: 0;
      padding-left: 30px;
    }
    &:hover {
      outline-width: 0;
    }
  }
}
