.request {
  color: var(--bs-white);
  &.method {
    &__GET {
      background-color: var(--bs-info); // success
    }
    &__POST {
      background-color: var(--bs-success); // success
    }
    &__PUT {
      background-color: var(--bs-warning); // info
    }
    &__PATCH {
      background-color: var(--bs-pink); // purple(ish)
    }
    &__DELETE {
      background-color: var(--bs-danger); // error
    }
    &__HEAD {
      background-color: var(--bs-success); // success
    }
    &__OPTIONS {
      background-color: var(--bs-purple); // purple
    }
  }
}
