.form-label {
  font-weight: 500;
  margin: 0 0 3px 3px;
  color: var(--bs-heading-color);
}
.form-control {
  box-shadow: var(--bs-box-shadow);
  &:focus {
    box-shadow: none;
  }
  // change the placeholder color
  &::placeholder {
    color: #8998a9;
  }
}

.invalid-feedback {
  margin-top: 0;
}
